import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
    border: "none",
    fontStyle: "normal",
    color: "#fff",
  },
  header: {
    marginTop: "20vh",
    color: "white",
    textAlign: "center",
  },
  description: {
    color: "white",
    textAlign: "center",
    maxWidth: "650px",
    margin: "24px auto 100px auto",
  },
  imgWrapper: {
    margin: "auto",
    display: "flex",
  },
});
