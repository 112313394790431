import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const SEO = () => {
  const { site } = useStaticQuery(graphqlQuery);

  return (
    <Helmet
      // htmlAttributes={{ lang }}
      title={site.siteMetadata?.title}
      meta={[
        {
          name: `description`,
          content: site.siteMetadata?.name,
        },
        {
          name: `keywords`,
          content: site.siteMetadata?.keyword,
        },
        {
          property: `og:title`,
          content: `title`,
        },
        {
          property: `og:description`,
          content: `title`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].concat()} // (meta)
    />
  );
};

export default SEO;

const graphqlQuery = graphql`
  {
    site {
      siteMetadata {
        author
        copyright
        description
        keywords
        siteUrl
        title
      }
    }
  }
`;
