import * as React from "react";
import { useStyles } from "./PageUnderConstruction.styles";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";
import { UnderConstructionIllustration } from "../../../common/images/illustrations/UnderConstructionIllustration";

const PageUnderConstruction = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.header}>
            <HeadingDash justify="center" />
            This Page is under construction
          </Typography>
          <Typography variant="body2" className={classes.description}>
            We are sorry for the inconveniences
          </Typography>
        </Grid>
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          className={classes.imgWrapper}
        >
          <UnderConstructionIllustration />
        </Grid>
      </Grid>
    </section>
  );
};

export default PageUnderConstruction;
