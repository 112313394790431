import React from "react";
import PageUnderConstruction from "../Sections/PageUnderConstruction/PageUnderConstruction";

const EpMarketplace = () => {
  return (
    <>
      <PageUnderConstruction />
    </>
  );
};

export default EpMarketplace;
